<template>
  <div class="qr-order">
    <QrOrderBill
      v-show="isShowPayment"
      :items="items"
      :currencySymbol="currencySymbol"
      :calculatedData="calculated"
      :totalToPay="totalToPay"
      :tipsAmount="tipsAmount"
      :hasSelected="!!selectedItems.length"
      :serviceFee="toPayServiceFee"
    />
    <div class="qr-order__list">
      <QrOrderLitItem
        v-for="item in sortedItems"
        :key="item.uid"
        :item="item"
        :currencySymbol="currencySymbol"
        :isSelected="checkIsItemSelected(item.uid)"
        :isSplitMode="isSplitMode"
        @select="onItemSelect(item.uid)"
      />
    </div>
    <div v-if="isShowPayment" class="qr-order__actions">
      <QrOrderEmail
        v-if="isEmailShow"
        v-model="emailData"
        :currencySymbol="currencySymbol"
        :serviceFee="toPayServiceFee"
        :currencyCode="currencyCode"
      />
      <div class="qr-order__actions-buttons">
        <div
          role="button"
          :class="['qr-order__action', isPayButtonDisabled && 'qr-order__action--disabled']"
          @click="onPayClick"
        >
          {{ $t('qrOrder.toPay') }}
        </div>
        <div
          v-if="isSplitMode || isEmailShow"
          role="button"
          class="qr-order__action"
          @click="cancelAll"
        >
          {{ $t('qrOrder.toCancel') }}
        </div>
        <div
          v-else
          role="button"
          :class="['qr-order__action', !unpaidItems.length && 'qr-order__action--disabled']"
          @click="showSplitDrawer"
        >
          {{ $t('qrOrder.splitBill') }}
        </div>
      </div>
    </div>
    <QrOrderTips
      v-show="isTipsDrawerOpen"
      :currencySymbol="currencySymbol"
      :itemsToPay="itemsToPay"
      :placeUid="placeUid"
      :objectUid="objectUid"
      :checkinUid="activeCheckinUid"
      :userUid="userUid"
      @tipsDrawerClose="closeTipsDrawer"
      @calculated="updateCalculatedData"
    />
    <QrOrderBillSplit
      v-if="isSplitDrawerOpen"
      :currencySymbol="currencySymbol"
      :billAmount="calculatedUnpaid.total"
      @splitApply="splitApplyHandler"
      @startSplitMode="startSplitModeHandler"
      @splitDrawerClose="closeSplitDrawer"
    />
  </div>
</template>

<script>
import { CURRENCIES } from '@/consts'
import QrOrderBill from './QrOrderBill'
import QrOrderLitItem from './QrOrderLitItem'
import QrOrderTips from './QrOrderTips'
import QrOrderBillSplit from './QrOrderBillSplit'
import QrOrderEmail from './QrOrderEmail'
import {ordersPayNew as ordersPayNewApi} from '@/init/api'

export default {
  components: {
    QrOrderBill,
    QrOrderLitItem,
    QrOrderTips,
    QrOrderBillSplit,
    QrOrderEmail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    placeUid: {
      type: String,
      required: true,
    },
    userUid: {
      type: String,
      required: true,
    },
    hasTips: {
      type: Boolean,
      default: false,
    },
    currencyCode: {
      type: String,
      default: 'USD'
    },
  },
  data() {
    return {
      isTipsDrawerOpen: false,
      isSplitDrawerOpen: false,
      isSplitMode: false,
      isEmailShow: false,
      selectedItems: [],
      emailData: {
        email: '',
        isAgreeLicence: true,
        isAgreeService: true,
      },
      tipsAmount: 0,
      calculated: {
        mayberFee: 0,
        serviceFee: 0,
        total: 0,
      },
    }
  },
  computed: {
    activeCheckin() {
      return this.$checkins?.[0]
    },
    activeCheckinUid() {
      return this.activeCheckin?.uid
    },
    objectUid() {
      return this.activeCheckin?.object_uid
    },
    currencySymbol() {
      return CURRENCIES[this.activeCheckin?.currency || 'USD']
    },
    isShowPayment() {
      return true || !!Number(this.leftToPay)
    },
    itemsToPay() {
      const { selectedItems, unpaidItems: items } = this

      if (!selectedItems.length) {
        return items
      }
      return items.filter((item) => selectedItems.includes(item.uid))
    },
    isPayButtonDisabled() {
      return !this.unpaidItems.length || this.isSplitMode && !this.selectedItems.length
    },
    unpaidItems() {
      return this.items.filter((item) => item.paid === false);
    },
    toPayUntaxed() {
      return Math.round10(Number(this.calculated.total) + Number(this.tipsAmount), 2)
    },
    toPayServiceFee() {
      return Math.round10((this.toPayUntaxed * this.calculated.serviceFee) / 100, 2)
    },
    totalToPay() {
      return Math.round10(this.toPayUntaxed + this.toPayServiceFee, 2)
    },
    sortedItems() {
      return this.items.slice().sort((a, b) => a.paid && !b.paid ? 1 : a.paid || b.paid ? -1 : 0 )
    },
    hasLockedItem() {
      return this.items.some((item) => item.locked)
    }
  },
  watch: {
    'items.length': {
      immediate: true,
      handler(value) {
        if (!value) {
          return
        }

        this.$emit('orderLengthFromIikoChanged')
      },
    },
    hasLockedItem(value) {
      if (value && !window.paymentWindow) {
        this.showLockedItemsError()
        this.cancelAll()
      }
    },
  },
  methods: {
    checkIsItemSelected(uid) {
      return this.selectedItems.includes(uid)
    },
    showSplitDrawer() {
      if (this.hasLockedItem) {
        this.showLockedItemsError()
        return
      }

      this.startSplitModeHandler()
    },
    closeSplitDrawer() {
      this.isSplitDrawerOpen = false
    },
    splitApplyHandler(amount) {
      this.closeSplitDrawer()
    },
    startSplitModeHandler() {
      this.isSplitMode = true
      this.closeSplitDrawer()
    },
    onItemSelect(uid) {
      if (!this.isSplitMode) {
        return
      }

      if (this.checkIsItemSelected(uid)) {
        this.selectedItems = this.selectedItems.filter(
          (itemId) => itemId !== uid
        )
      } else {
        this.selectedItems.push(uid)
      }
    },
    showTipsDrawer() {
      this.isTipsDrawerOpen = true
    },
    closeTipsDrawer(tipsAmount = 0) {
      this.isTipsDrawerOpen = false
      this.isEmailShow = true
      this.tipsAmount = tipsAmount
      // this.$emit('pay')
    },
    isEmailValid(email) {
      return email && this.$utils.validateEmail(email)
    },
    async getPaymentLink(paymentType, email) {
      try {
        const {info: {confirmation_url}} = await ordersPayNewApi(
          this.placeUid, 
          this.itemsToPay.map((item) => item.uid), 
          paymentType, 
          this.userUid, 
          this.tipsAmount,
          email,
        )
        return {paymentUrl: confirmation_url}
      } catch (error) {
        return {error}
      }
    },
    showPaymetError(error) {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('error'),
          subtitle:
            error.code === 'GENERAL_ERROR'
              ? this.$t('roomService.generalErorr')
              : error.description || '',
          // subtitle: this.$t('roomService.paymentMethodNotSupported'),
          icon: '/img/iconAttention.svg',
        },
        actions: [
          {
            id: 'ok',
            text: 'OK',
          },
        ],
        resolve: () => ({}),
        reject: () => ({}),
      })
    },
    async payCallback(paymentType) {
      const isGeorgianBank = this.activeCheckin.currency === 'GEL'
      const localPaymentType = !isGeorgianBank && ['applePay', 'googlePay', 'card'].includes(
          paymentType
        )
          ? 'card'
          : paymentType
      // Если выбрана наличка
      if (localPaymentType === 'cash') {
        await this.$parent.callWaiter(true)
        this.cancelPayment()
        return
      }

      this.$store.dispatch('places/showLoader', true)
      window.paymentWindow = window.open("/loader.html", "_blank")

      const {paymentUrl, error} = await this.getPaymentLink(localPaymentType, this.emailData.email)
      if (error) {
        this.showPaymetError(error)
        window.paymentWindow?.close()
        this.$store.dispatch('places/hideLoader')
        return
      }

      window.paymentWindow.location.href = paymentUrl
      this.$store.dispatch('places/hideLoader')
      this.cancelAll();
    },
    checkIsAgreementsOk() {
      const {emailData: {isAgreeLicence, isAgreeService}, calculated: {serviceFee}} = this;

      if (serviceFee && !isAgreeService) {
        return false
      }

      if (!isAgreeLicence) {
        return false
      }

      return true
    },
    initPay() {
      if (!this.isEmailValid(this.emailData.email)) {
        this.showErrorEmailModal()
        return
      }

      if (!this.checkIsAgreementsOk()) {
        this.showErrorFeeModal()
        return
      }

      this.$parent.getPaymentType(this.totalToPay, this.payCallback)
    },
    onPayClick() {
      if (this.hasLockedItem) {
        this.showLockedItemsError()
        return
      }
      if (this.isEmailShow) {
        this.initPay()
        return
      }
      if (this.hasTips) {
        this.showTipsDrawer()
      } else {
        this.closeTipsDrawer(0);
      }
    },
    cancelSplitMode() {
      this.isSplitMode = false
      this.selectedItems = []
    },
    cancelPayment() {
      this.isEmailShow = false
    },
    resetTips() {
      this.isTipsDrawerOpen = false
      this.tipsAmount = 0
    },
    cancelAll() {
      this.cancelSplitMode()
      this.cancelPayment()
      this.resetTips()
    },
    
    async getUserEmail() {
      this.emailData.email = await window.ordersStore.getUserEmail()
    },
    updateCalculatedData({mayberFee, serviceFee, total}) {
      this.calculated = {
        mayberFee,
        serviceFee,
        total,
      };
    },
    showErrorEmailModal() {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('error'),
          subtitle: this.$t('auth.invalidEmail'),
          icon: '/img/iconAttention.svg',
        },
        actions: [
          {
            id: 'ok',
            text: 'OK',
          },
        ],
        resolve: () => ({}),
        reject: () => ({}),
      })
    },
    showErrorFeeModal() {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('delivery.errors.NOT_CONFIRM_FEE.title'),
          subtitle: this.$t('delivery.errors.NOT_CONFIRM_FEE.text'),
          icon: '/img/iconAttention.svg',
        },
        actions: [
          {
            id: 'ok',
            text: 'OK',
          },
        ],
        resolve: () => ({}),
        reject: () => ({}),
      })
    },
    showLockedItemsError() {
      this.$bus.$emit('openContextMenu', {
        config: {
          type: 'modal',
          style: 'pink',
          title: this.$t('delivery.errors.HAS_LOCKED_ITEMS.title'),
          subtitle: this.$t('delivery.errors.HAS_LOCKED_ITEMS.text'),
          icon: '/img/iconAttention.svg',
        },
        actions: [
          {
            id: 'ok',
            text: 'OK',
          },
        ],
        resolve: () => ({}),
        reject: () => ({}),
      })
    },
  },
  mounted() {
    this.getUserEmail()
  }
}
</script>

<style lang="scss">
.qr-order {
  background: $black !important;

  &__list {
    @include flex(column, flex-start, center);
    gap: 4px;
    width: 100%;
  }

  &__actions-wrapper {
    position: fixed;
    inset: 0;
    top: unset;
  }

  &__actions {
    position: fixed;

    inset: 0;
    top: unset;
    padding: 0 16px 18px 16px;
    background: $black;
    z-index: 2;
    border-radius: 8px 8px 0 0;
  }

  &__actions-buttons {
    background: rgba(51, 51, 51, 1);
    border-radius: 24px;
    padding: 2px;
    gap: 7px;
    height: 48px;
    margin-top: 18px;
    @include flex(row, flex-start, center);
  }

  &__action {
    width: 100%;
    border-radius: 24px;
    height: 100%;
    background: $white;
    color: $black;
    @include flex(row, center, center);
    @include font(15, 18, 600);
    text-transform: uppercase;

    &--hidden {
      width: 0;
      visibility: hidden;
    }

    &:last-child {
      background: rgba(255, 255, 255, 0.1);
      color: $white;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
</style>
