<template>
  <div class="order-bill">
    <transition name="fade">
      <div class="order-bill__row">
        <div class="order-bill__col">{{ $t('qrOrder.billAmount') }}</div>
        <div class="order-bill__col">{{ currencySymbol }} {{ billRequired }}</div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="calculatedData.mayberFee" class="order-bill__row">
        <div class="order-bill__col">{{ $t('T000009') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ calculatedData.mayberFee }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="totalPaid" class="order-bill__row">
        <div class="order-bill__col">{{ $t('qrOrder.paid') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ totalPaid }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-show="hasSelected && calculatedData.total" class="order-bill__row">
        <div class="order-bill__col">{{ $t('qrOrder.yourPart') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ calculatedData.total }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="tipsAmount" class="order-bill__row">
        <div class="order-bill__col">{{ $t('qrOrder.theTips') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ tipsAmount }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="serviceFee" class="order-bill__row">
        <div class="order-bill__col">{{ $t('order.serviceFee') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ serviceFee }}
        </div>
      </div>
    </transition>
    <div class="order-bill__row">
      <div class="order-bill__col order-bill__col--highlight">{{ $t('qrOrder.total') }}
        <span v-if="totalAllPostfix" class="order-bill__note">({{ totalAllPostfix }})</span>
      </div>
      <div class="order-bill__col order-bill__col--total">
        {{ currencySymbol }} {{ totalToPay }}
      </div>
    </div>
    <transition name="fade">
      <div v-if="leftToPay" class="order-bill__row order-bill__row--small">
        <div class="order-bill__col">{{ $t('qrOrder.leftToPay') }}</div>
        <div class="order-bill__col">
          {{ currencySymbol }} {{ leftToPay }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import payMixin from '@/mixins/payMixin'
import additionalPaymentsMixin from '@/mixins/additionalPaymentsMixin'

export default {
  mixins: [payMixin, additionalPaymentsMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    calculatedData: {
      type: Object,
      default: () => ({
        mayberFee: 0,
        serviceFee: 0,
        total: 0,
      }),
    },
    tipsAmount: {
      type: Number,
      default: 0
    },
    hasSelected: {
      type: Boolean,
      default: false,
    },
    totalToPay: {
      type: Number,
      default: 0
    },
    serviceFee: {
      type: Number,
      default: 0
    }
  },
  computed: {
    billRequired() {
      return this.items.reduce(
        (accumulator, item) => (accumulator += Number(item.price)),
        0
      )
    },
    totalPaid() {
      return this.items.reduce((accumulator, item) => {
        if (item.paid) {
          accumulator += Number(item.price)
        }
        return accumulator
      }, 0)
    },
    somePaidAndSomeSelected() {
      return Boolean(this.totalPaid && this.hasSelected)
    },
    totalAllPostfix() {
      // когда что то оплачено и ничего не выбрано
      if (this.totalPaid && !this.hasSelected) {
        return this.$t('qrOrder.left')
      }

      // когда что то оплачено и что-то выбрано
      if (this.hasSelected) {
        return this.$t('qrOrder.yourPart')
      }

      return ''
    },
    leftToPay() {
      return this.somePaidAndSomeSelected ? (this.billRequired - this.totalPaid - this.calculatedData.total) : 0
    },
  },
}
</script>

<style lang="scss">
.order-bill {
  @include flex(column, flex-start, center);
  padding: 22px 16px;
  margin-bottom: 4px;
  background: #1a1a1a;
  @include font(18, 21, 400);
  color: $white;
  gap: 14px;

  &__row {
    @include flex(row, space-between, center);
    width: 100%;
    border-bottom: 2px dotted #333333;

    &--small {
      @include font(14, 17, 400);
      color: $secondaryText;
      margin-top: -8px;
    }
  }

  &__col {
    background: #1a1a1a;
    transform: translateY(6px);
    padding-right: 5px;
    padding-left: 0;
    height: 100%;
    @include flex(row, center, center);

    &:last-child {
      padding-right: 0;
      padding-left: 5px;
    }

    &--total {
      color: $goldenMain;
    }

    &--highlight {
      font-weight: 600;
    }
  }

  &__note {
    color: $secondaryText;
    margin-left: 5px;
    font-weight: 400;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s ease-in-out;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
